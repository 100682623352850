.flag-icon {
  margin-right: 0.1em;
}

.invoice-and-payments-modal td {
  border: 1px solid black;
  padding: 0.5em;
}

.invoice-and-payments-modal th {
  background: white;
  color: black;
  padding: 0.5em;
  border: 1px solid black;
}

.invoice-and-payments-modal .flex-row {
  width: 25%;
}

.invoice-and-payments-modal .flex-row,
.invoice-and-payments-modal .flex-cell {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .invoice-and-payments-modal th,
  .invoice-and-payments-modal td {
    width: auto;
    display: block;
    border: 0;
  }

  .invoice-and-payments-modal th {
    border-left: solid 1px black;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
  }

  .invoice-and-payments-modal td {
    border-left: solid 1px black;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
  }

  .invoice-and-payments-modal .flex-row {
    width: 100%;
  }
}

/* Date picker custom CSS */
.invoice-and-payments-datepicker .react-datepicker__input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-and-payments-datepicker .react-datepicker__input-container input {
  border: 1px solid black;
  padding: 4px;
  border-radius: 4px;
  width: 55%;
  font-size: 1rem;
}
