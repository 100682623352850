@tailwind base;
/* @tailwind components;
@tailwind utilities; */
/* Also figure out the dumb input:autofill>moz-autofill-background that fills inputs when auto-filled */

body.no-scroll {
  overflow: hidden;
}

.App {
  /* text-align: center; */
  background-color: white;
  min-height: 100vh;
  min-width: 100%;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input {
  background-color: white;
}

/* city country dropdown */
.stdropdown-item {
  color: black;
  background-color: white;
}

.stdropdown-input.stsearch-box > input {
  color: black;
  background-color: white;
}

/* Hide the up/down arrows in Chrome, Safari, Edge, Opera*/
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::selection {
  background: #b4d5fe;
}

/**
*
* FIREFOX
**/
/* Hide the up/down arrows in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
