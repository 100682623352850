/* Basic document styles */
/* :root {
  color-scheme: dark;
} */

.tiptap.ProseMirror {
  padding: 0 1rem 1rem 1rem;
}

.tiptap ul {
  list-style: disc;
  padding: 0 1rem;
}

.tiptap ol {
  list-style: decimal;
  padding: 0 1rem;
}


.rich-text-editor {
  box-sizing: border-box;
  margin: 0;
  font-family: sans-serif;
  line-height: 1.4;
  color: #e6e6e6;
}

/* Basic editor styles */
.tiptap {
  cursor: text !important;
  /* min-height: calc(100vh - 2rem); */
}

.tiptap:focus-visible {
  outline: none;
}

.tiptap>*+* {
  margin-top: 0.75em;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

.tiptap h1 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 600;
}

.tiptap h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.tiptap h3 {
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 500;
}

.tiptap code {
  background: #c8c5c5;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 0.5rem;
  padding: 0.2rem;
}

.tiptap pre {
  cursor: text;
  background: #c8c5c5;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
  border: none;
}

.tiptap img {
  max-width: 100%;
  height: auto;
}

.tiptap blockquote {
  margin-left: 0;
  padding-left: 1rem;
  border-left: 2px solid #000000;
}

.tiptap hr {
  border: none;
  border-top: 2px solid #000000;
  margin: 2rem 0;
}